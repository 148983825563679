import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import LabRooms from './LabRooms';
import Equipment from './Equipment';
import Bookings from './Bookings';
import BookingForm from './BookingForm';
import Login from './Login';
import CalendarView from './CalendarView';
import { Container, Typography, AppBar, Toolbar, Button } from '@mui/material';

function App() {
  const [user, setUser] = useState(null);
  const [selectedLabRoom, setSelectedLabRoom] = useState('');

  useEffect(() => {
    // 检查用户是否已经登录
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setUser(session.user);
      }
    };

    checkSession();

    // 监听登录状态变化
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        setUser(session.user);
      } else if (event === 'SIGNED_OUT') {
        setUser(null);
      }
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setUser(null);
  };

  return (
    <Router>
      <div>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Li's Lab Booking System
            </Typography>
            {user && (
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Container>
          <Routes>
            <Route
              path="/"
              element={
                user ? (
                  <Navigate to="/calendar" />
                ) : (
                  <Login onLogin={(user) => setUser(user)} />
                )
              }
            />
            <Route
              path="/calendar"
              element={
                user ? (
                  <CalendarView
                    selectedLabRoom={selectedLabRoom}
                    setSelectedLabRoom={setSelectedLabRoom}
                  />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/booking"
              element={
                user ? (
                  <BookingForm user={user} selectedLabRoom={selectedLabRoom} />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
          </Routes>
        </Container>
      </div>
    </Router>
  );
}

export default App;