import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Container, Typography, Select, MenuItem, FormControl, InputLabel, Box, TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './CalendarView.css';
import { parseISO, format } from 'date-fns';

function CalendarView({ selectedLabRoom, setSelectedLabRoom }) {
  const [bookings, setBookings] = useState([]);
  const [labRooms, setLabRooms] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [users, setUsers] = useState({});
  const calendarRefs = useRef({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchLabRooms();
    fetchEquipment();
    fetchBookings();
  }, [selectedLabRoom, selectedDate]);

  const fetchLabRooms = async () => {
    const { data, error } = await supabase.from('lab_rooms').select('*');
    if (error) console.log('error', error);
    else setLabRooms(data);
  };

  const fetchEquipment = async () => {
    const { data, error } = await supabase.from('equipment').select('*');
    if (error) console.log('error', error);
    else setEquipmentList(data);
  };

  const fetchBookings = async () => {
    const { data, error } = await supabase.from('bookings').select('*');
    if (error) console.log('error', error);
    else {
      console.log('Bookings:', data);
      setBookings(data);
      fetchUsers(data);
    }
  };

  const fetchUsers = async (bookings) => {
    const userMap = {};
    for (const booking of bookings) {
      const { data, error } = await supabase
        .rpc('get_user_info', { user_id: booking.user_id })
        .single();

      if (error) {
        console.log('error', error);
      } else {
        userMap[data.id] = {
          email: data.email,
          displayName: data.display_name || 'Unknown User',
        };
      }
    }
    console.log('Users:', userMap);
    setUsers(userMap);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    Object.values(calendarRefs.current).forEach((calendar) => {
      if (calendar) {
        calendar.getApi().gotoDate(date);
      }
    });
  };

  const handleLabRoomChange = (event) => {
    setSelectedLabRoom(event.target.value);
  };

  const handleToday = () => {
    const today = new Date();
    setSelectedDate(today);
    Object.values(calendarRefs.current).forEach((calendar) => {
      if (calendar) {
        calendar.getApi().today();
      }
    });
  };

  const handleNextDay = () => {
    const nextDay = new Date(selectedDate);
    nextDay.setDate(selectedDate.getDate() + 1);
    setSelectedDate(nextDay);
    Object.values(calendarRefs.current).forEach((calendar) => {
      if (calendar) {
        calendar.getApi().gotoDate(nextDay);
      }
    });
  };

  const handleViewChange = (view) => {
    Object.values(calendarRefs.current).forEach((calendar) => {
      if (calendar) {
        calendar.getApi().changeView(view);
      }
    });
  };

  const handleEventClick = (info) => {
    navigate('/booking', {
      state: {
        bookingId: info.event.id,
        equipmentId: info.event.resourceId,
        startTime: info.event.start,
        endTime: info.event.end,
        labRoomId: selectedLabRoom,
      },
    });
  };

  const handleDateClick = (arg) => {
    console.log('Date Clicked:', arg.date);

    const startTime = arg.date;
    const endTime = new Date(startTime.getTime() + 2 * 60 * 60 * 1000);

    navigate('/booking', {
      state: {
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        labRoomId: selectedLabRoom,
      },
    });
  };

  const filteredEquipment = selectedLabRoom
    ? equipmentList.filter((equipment) => equipment.lab_room_id === selectedLabRoom).sort((a, b) => a.name.localeCompare(b.name))
    : equipmentList.sort((a, b) => a.name.localeCompare(b.name));

  const events = bookings
    .filter((booking) =>
      filteredEquipment.some((equipment) => equipment.id === booking.equipment_id)
    )
    .map((booking) => {
      const user = users[booking.user_id] || { email: 'Unknown', displayName: 'Unknown User' };
      const equipment = equipmentList.find((e) => e.id === booking.equipment_id);

      const startLocal = parseISO(booking.start_time);
      const endLocal = parseISO(booking.end_time);

      return {
        id: booking.id,
        resourceId: booking.equipment_id,
        title: `${equipment ? equipment.name : 'Unknown Equipment'} (${user.displayName})`,
        start: startLocal,
        end: endLocal,
        extendedProps: {
          description: booking.description || '', // Add description
        },
      };
    });

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Booking Calendar
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 4 }}>
        <FormControl fullWidth>
          <InputLabel>Select Lab Room</InputLabel>
          <Select value={selectedLabRoom} onChange={handleLabRoomChange} label="Select Lab Room">
            <MenuItem value="">All Lab Rooms</MenuItem>
            {labRooms.map((room) => (
              <MenuItem key={room.id} value={room.id}>
                {room.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, marginBottom: 4 }}>
        <Button variant="contained" onClick={handleToday}>
          Today
        </Button>
        <Button variant="contained" onClick={handleNextDay}>
          Next Day
        </Button>
        <Button variant="contained" onClick={() => handleViewChange('timeGridDay')}>
          Day View
        </Button>
        <Button variant="contained" onClick={() => handleViewChange('timeGridWeek')}>
          Week View
        </Button>
      </Box>

      <Box 
        sx={{ 
          display: 'flex', 
          gap: 2, 
          minWidth: 'fit-content', // 保证内容可以延展
          overflowX: 'auto', // 启用横向滚动
          '&::-webkit-scrollbar': {
            height: 8,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: 4,
          }
        }}
        className="calendar-week-container"
      >
        {filteredEquipment.map((equipment, index) => {
          const equipmentEvents = events.filter((event) => event.resourceId === equipment.id);
          return (
            <Box 
              key={equipment.id} 
              sx={{ 
                minWidth: 350, // 每个日历最小宽度
                width: '100%', 
              }}
            >
              <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem', textAlign: 'center' }}>
                {equipment.name}
              </Typography>
              <FullCalendar
                plugins={[timeGridPlugin, interactionPlugin]}
                initialView="timeGridDay"
                initialDate={selectedDate}
                events={equipmentEvents}
                ref={(ref) => (calendarRefs.current[equipment.id] = ref)}
                headerToolbar={false}
                height={650} // 固定高度保证垂直空间
                allDaySlot={false}
                slotDuration="00:30:00"
                slotLabelInterval="00:30:00"
                slotMinWidth={120} // 时间列最小宽度
                slotLabelFormat={{ 
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true,
                  meridiem: 'short'
                }}
                slotLabelDidMount={() => {}}
                eventClick={handleEventClick}
                dateClick={handleDateClick}
                nowIndicator
                eventContent={(arg) => (
                  <div style={{ fontSize: '0.8rem' }}>
                    <strong>{arg.event.title}</strong>
                    <div>{arg.event.extendedProps.description}</div>
                  </div>
                )}
                views={{
                  timeGridWeek: {
                    dayHeaderFormat: { weekday: 'short', day: '2-digit' }, // 修改为2位数字格式
                    slotMinWidth: 100,
                  }
                }}
                eventMinHeight={38} 
                expandRows={true} 
                stickyHeaderDates={false} 
                slotMinTime="00:00:00"  // 最早显示时间
                slotMaxTime="24:00:00"  // 最晚显示时间
                contentHeight="auto"    // 自动高度适配
              />
            </Box>
          );
        })}
      </Box>
    </Container>
  );
}

export default CalendarView;