import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { Container, Typography, TextField, Button, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO, addHours } from 'date-fns';

function BookingForm({ user, selectedLabRoom }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { bookingId, equipmentId, startTime, endTime, labRoomId } = location.state || {};
  const [equipmentList, setEquipmentList] = useState([]);
  const [booking, setBooking] = useState({
    equipment_id: equipmentId || '',
    start_time: startTime ? new Date(startTime) : new Date(),
    end_time: endTime ? new Date(endTime) : new Date(),
    description: '', // Add description field
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEquipment = async () => {
      let query = supabase.from('equipment').select('*');
      if (labRoomId || selectedLabRoom) {
        query = query.eq('lab_room_id', labRoomId || selectedLabRoom);
      }
      const { data, error } = await query;

      if (error) {
        console.log('Error fetching equipment:', error);
      } else {
        console.log('Equipment List:', data);
        setEquipmentList(data);
      }
      setIsLoading(false);
    };

    fetchEquipment();
  }, [labRoomId, selectedLabRoom]);

  useEffect(() => {
    if (bookingId) {
      const fetchBooking = async () => {
        const { data, error } = await supabase
          .from('bookings')
          .select('*')
          .eq('id', bookingId)
          .single();

        if (error) {
          console.log('Error fetching booking:', error);
        } else {
          console.log('Booking Details:', data);

          const startLocal = parseISO(data.start_time);
          const endLocal = parseISO(data.end_time);

          setBooking({
            equipment_id: data.equipment_id,
            start_time: startLocal,
            end_time: endLocal,
            description: data.description || '', // Add description
          });

          setIsEditMode(true);
          setIsOwner(data.user_id === user.id);
        }
      };

      fetchBooking();
    }
  }, [bookingId, user.id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { equipment_id, start_time, end_time, description } = booking;

    if (!(start_time instanceof Date) || !(end_time instanceof Date)) {
      console.error('Invalid date:', start_time, end_time);
      return;
    }

    // Convert local time to UTC time by adding 8 hours
    const startTimeUTC = addHours(start_time, 8).toISOString();
    const endTimeUTC = addHours(end_time, 8).toISOString();

    // Check for overlapping bookings
    const isOverlap = await checkOverlap(equipment_id, startTimeUTC, endTimeUTC, bookingId);
    if (isOverlap) {
      alert('This time slot is already booked. Please choose a different time.');
      return;
    }

    // Submit the booking
    if (isEditMode) {
      const { data, error } = await supabase
        .from('bookings')
        .update({
          equipment_id,
          start_time: startTimeUTC,
          end_time: endTimeUTC,
          description, // Add description
        })
        .eq('id', bookingId)
        .select('*');

      if (error) {
        console.log('Error updating booking:', error);
      } else {
        console.log('Booking updated:', data);
        navigate('/calendar');
      }
    } else {
      const { data, error } = await supabase
        .from('bookings')
        .insert([{
          equipment_id,
          start_time: startTimeUTC,
          end_time: endTimeUTC,
          user_id: user.id,
          description, // Add description
        }])
        .select('*');

      if (error) {
        console.log('Error creating booking:', error);
      } else {
        console.log('Booking created:', data);
        navigate('/calendar');
      }
    }
  };

  const handleDeleteBooking = async () => {
    const isConfirmed = window.confirm('Are you sure you want to delete this booking?');
    if (isConfirmed) {
      const { data, error } = await supabase
        .from('bookings')
        .delete()
        .eq('id', bookingId);

      if (error) {
        console.log('Error deleting booking:', error);
      } else {
        console.log('Booking deleted:', data);
        navigate('/calendar');
      }
    }
  };

  const checkOverlap = async (equipment_id, start_time, end_time, bookingId) => {
    console.log('Checking Overlap - Start:', start_time);
    console.log('Checking Overlap - End:', end_time);

    let query = supabase
      .from('bookings')
      .select('*')
      .eq('equipment_id', equipment_id);

    // Only add neq condition if bookingId is defined
    if (bookingId) {
      query = query.neq('id', bookingId);
    }

    const { data, error } = await query;

    if (error) {
      console.log('Error checking overlap:', error);
      return false;
    }

    // Log existing bookings
    console.log('Existing Bookings:', data);

    const isOverlap = data.some((booking) => {
      const bookingStart = addHours(new Date(booking.start_time), 8);
      const bookingEnd = addHours(new Date(booking.end_time), 8);

      console.log('Comparing with Booking:', bookingStart, bookingEnd);

      return (
        (new Date(start_time) < bookingEnd && new Date(end_time) > bookingStart) ||
        (new Date(start_time) >= bookingStart && new Date(start_time) < bookingEnd) ||
        (new Date(end_time) > bookingStart && new Date(end_time) <= bookingEnd)
      );
    });

    return isOverlap;
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {isEditMode ? 'Edit Booking' : 'Create Booking'}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Equipment</InputLabel>
          <Select
            value={booking.equipment_id || ''}
            onChange={(e) => setBooking({ ...booking, equipment_id: e.target.value })}
            label="Equipment"
            required
            disabled={isEditMode || isLoading}
          >
            {isLoading ? (
              <MenuItem disabled>Loading...</MenuItem>
            ) : equipmentList.length > 0 ? (
              equipmentList.map((equipment) => (
                <MenuItem key={equipment.id} value={equipment.id}>
                  {equipment.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No equipment available</MenuItem>
            )}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Start Time"
            value={booking.start_time}
            onChange={(date) => setBooking({ ...booking, start_time: date })}
            renderInput={(params) => <TextField {...params} required />}
          />
          <DateTimePicker
            label="End Time"
            value={booking.end_time}
            onChange={(date) => setBooking({ ...booking, end_time: date })}
            renderInput={(params) => <TextField {...params} required />}
          />
        </LocalizationProvider>
        <TextField
          label="Description"
          value={booking.description}
          onChange={(e) => setBooking({ ...booking, description: e.target.value })}
          multiline
          rows={4}
        />
        <Button type="submit" variant="contained">
          {isEditMode ? 'Update Booking' : 'Create Booking'}
        </Button>
        {isEditMode && isOwner && (
          <Button variant="contained" color="error" onClick={handleDeleteBooking}>
            Delete Booking
          </Button>
        )}
      </Box>
    </Container>
  );
}

export default BookingForm;